import * as React from "react";
import MainPageHeader from "../components/Templates/PageHeaders/MainPageHeader";
import Layout from "../components/Layout/MainPageLayout";

const NotFoundPage = () => {
  return (
    <Layout selected="About Me">
      <MainPageHeader pageHeading="Oops!" pageDescription="Page not found :(" />
    </Layout>
  );
};

export default NotFoundPage;
